import { graphql } from "gatsby";
import React, { Fragment } from "react";
import ArticleB from "../components/ArticleB";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import LocalScript from "../components/LocalScript";
import { SEO } from "../components/SEO";

const Article = ({ data: { sanityArticle, sanitySite }, location }) => {
  return (
    <Fragment>
      <LocalScript
        html={`
            var disqus_config = function () {
              this.page.url = 'https://nickwolny.com/${location?.pathname}';
              this.page.identifier = '${sanityArticle?.id}'; 
            };
            (function() {
            var d = document, s = d.createElement('script');
            s.src = 'https://nick-wolny.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
          `}
      />
      <Layout headerData={sanitySite?.header}>
        <div className="px-4 lg:px-0">
          <ArticleB article={sanityArticle} />
        </div>
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Article;
export const Head = ({ data: { sanityArticle } }) => {
  return <SEO metadata={sanityArticle?.pageMetadata || {}} />;
};
export const query = graphql`
  query ($categoryId: String!, $articleId: String!) {
    sanityArticle(
      category: { elemMatch: { id: { eq: $categoryId } } }
      id: { eq: $articleId }
    ) {
      featuredImage {
        asset {
          extension
          url
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
      _rawFeaturedImageattribution
      templateType
      slug {
        current
      }
      pageMetadata {
        schema
        description
        keywords
        title
        canonical
        image {
          asset {
            url
          }
        }
      }
      heading
      id
      category {
        slug {
          current
        }
        title
        id
        description
      }
      author {
        name
        id
        email
        bio
      }
      _rawContent
      tableContent {
        title
        items
      }
      _createdAt(formatString: "MMMM DD YYYY")
      _updatedAt(formatString: "MMMM DD YYYY")
      readTime
      summary
    }
    sanitySite {
      header {
        links {
          label
          path
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
      }
    }
  }
`;
